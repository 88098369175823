<template>
  
        <div class="head" @touchmove.prevent :style="{background:bg }">
            <van-row align="center">
            <van-col span="8" class="left">

                            <img class="back" v-if="type=='0'"  src="../../assets/back_black.png" alt="" @click="$goBack()"> 
                            <img  class="back" v-if="type=='1'"  src="../../assets/img/back_white.png" alt="" @click="$goBack()"> 
                            <slot name="left"></slot>
            
             </van-col>
            <van-col span="8" class="center">
                            <div v-if="title">{{title}}</div>
                             <slot name="title"></slot> 

            </van-col>
            <van-col span="8" class="right">
                
                            <slot name="right"></slot> 
                    <img v-if="share" @click="$emit('input',showShare = true)"  src="../../assets/img/takeOutFood/share.png" alt="">
            </van-col>
</van-row>
        </div>
   
</template>

<script>
    export default {
            props:['type','backType','bg','share','title'],
           created(){ 

             
               this.setRem()
           }
    }
</script>

<style lang="less" scoped>
.head{
    width: 100%;
    box-sizing: border-box;
    padding: .21rem .06rem;
    background-size: auto ;
    background-repeat: no-repeat;
    background-position: center;
    font-family: PingFangSC-Semibold, PingFang SC;
    
    img{
        width: 26px;
        height: 25px;
        max-width: none;
        
    }
    .center{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .453333rem;
        font-weight: 600;
      
    }
    .right{
       
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
     
        font-size: .453333rem;
        font-family: SFProDisplay-Regular, SFProDisplay;
        box-sizing: border-box;
        padding-right: .266667rem;
    }
    .van-row{
          display: flex;
        align-items: center;
    }
    .left{
         display: flex;
        align-items: center;
       
    }
    .center{ 
        white-space: nowrap;
    }
}
</style>