import https from '@/router/https'
//订单详情
export function getOrderDetail(id) {
	return https.fetchGet('/mallapp/orderinfo/' + id)
}
//取消订单
export function cancelOrder(id) {
  return https.fetchPut('/mallapp/orderinfo/cancel/'+id);
}
// 确认收货
export function receiveOrder(id) {
	return https.fetchPut('/mallapp/orderinfo/receive/' + id)
}
export function movieDetail(id) {
	return https.fetchGet('/mallapp/movieorder/'+ id)
}
