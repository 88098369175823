import {aliPay} from '@/api/AliPay/index'
import {  wxPay } from "@/api/sureOrder/sureOrder"
import { Toast } from 'vant'
const  getWxPay = (data) =>{
  wxPay({id:data, tradeType:"APP"}  ).then(res=>{
      if(res.data.code===0){
      
       
            let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

         if (isIOS) {
          window.webkit.messageHandlers.wechatPay.postMessage( JSON.stringify(res.data.data) );
        }
        if (isAndroid) {
          window.android.wxpay( res.data.data.appId,
            res.data.data.partnerId,
            res.data.data.prepayId,
            res.data.data.nonceStr,
            res.data.data.timeStamp,
            res.data.data.packageValue,
            res.data.data.sign);
        }
      }else{ 
        Toast(res.data.msg)
      }
    })
  }


 const  submitAlipay = (id) => {
    aliPay({id:id, tradeType:"APP"}).then((res) => {
      if (res.status === 200) {
      
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
          window.webkit.messageHandlers.alipay.postMessage(res.data.data);
        }
        if (isAndroid) {
            window.android.alipay(res.data.data);
        }
      }else{ 
        Toast(res.data.msg)
      } 
    });
  }
  const  mallPay =(id,type)=>{
    if(type==='1'){
    getWxPay(id)

    }else{
        submitAlipay(id)
    }
  }
  
  export{
    mallPay
}