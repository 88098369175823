import { fetchPut } from '../../router/https'

function cancel(data) {
    return fetchPut('/mallapp/orderinfo/cancel/' + data)
}

function cancelHotel(data) {
    return fetchPut('/mallapp/hotelorderinfo/cancel/' + data)
}
export {
    cancel,
    cancelHotel
}