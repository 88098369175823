<template>
  <div class="keep" v-if="keep">

    <Head :type="0" title="订单详情" />



    <div class="main">
      <div class="warp">
        <div v-if="keep.listOrderItem[0].orderRefunds== null">

          <div class="title" v-if="timer&&keep.isPay=='0'&&keep.status!=5">
            待支付
          </div>

          <div class="title" v-if="timer === false&&keep.isPay=='0'&&keep.status!=5">
            订单超时
          </div>

          <div class="title" v-if="keep.status == '5'">
            已取消
          </div>

          <div class="title" v-if="keep.status == '1'&&keep.isPay=='1'">
            待使用
          </div>

          <div class="title" v-if=" !( keep.appraisesStatus - 0 )  && keep.status=='4'">
            待评价
          </div>

          <div class="title" v-if="( keep.appraisesStatus - 0 )  && keep.status=='4'">
            已完成
          </div>

        </div>




        <div v-if="keep.listOrderItem[0].status!='0'">

          <div class="title" v-if="keep.listOrderItem[0].orderRefunds.status == '1'">
            退款中
          </div>

          <div class="title" v-if="keep.listOrderItem[0].orderRefunds.status == '12'">
            退款失败
          </div>

          <div class="title" v-if="keep.listOrderItem[0].orderRefunds.status == '11'">
            退款完成
          </div>

        </div>

        <div class="btns">

          <div class="btn-area" v-if="keep.isPay == '0' && keep.status !== '5'">

            <img src="../../../assets/img/cancel_x.png" @click="remove()" />

            <div class="desc">取消订单</div>

          </div>



          <div class="btn-area"
            v-if=" (keep.isPay == '1' &&  keep.listOrderItem[0].orderRefunds == null && keep.paymentPrice ) &&  !['3','4'].includes(keep.status)">

            <!--  && Date.now() + 3600 * 1000 - new Date(keep.visitTime.replace(/-/g,'/')).getTime() < 0  -->

            <img src="../../../assets/icon-m.png"
              @click=" $router.push({path: 'refund', query: { id: keep.listOrderItem[0].id }})" />

            <div class="desc">申请退款</div>

          </div>




          <!-- (keep.status == '4'  &&( keep.appraisesStatus - 0 )   ) || -->
          <!-- || ['11','12'].includes(keep.orderRefundStatu) -->
          <div class="btn-area" v-if=" keep.status == '5' ">

            <img src="../../../assets/img/delete.png" @click="delOrder()" alt="" />
            <div class="desc">删除订单</div>

          </div>
          <!-- <div class="btn-area" v-if="keep.listOrderItem[0].status=='1'    || keep.status == '2'">

                      <img src="../../../assets/cancelRefund.png" @click="delOrder()" alt="" />
                      <div class="desc">取消退款</div>

          </div> -->

          <div class="btn-area" v-if=" !( keep.appraisesStatus - 0 )  && ['3','4'].includes(keep.status) ">

            <img src="../../../assets/img/pinglun.png" alt="" @click="$router.push({ 
                            path:'evaluate',query:{  id:keep.id,shopId:shopId,shopName:keep.shopInfo.name } })" />
            <div class="desc">去评价</div>

          </div>

          <!-- <p v-if="keep.status == '3' &&keep.appraisesStatus=='0'"></p> -->


          <!-- <p v-if="['3','4','5'].includes(keep.status) || !timer"></p> -->

          <div class="btn-area" v-if=" ['3','4','5'].includes(keep.status) || !timer">

            <img src="../../../assets/icon-add.png" alt="" @click="detail()" />
            <div class="desc">再来一单</div>

          </div>

          <!-- <p v-if="timer && keep.status!=5 && ! (keep.isPay - 0)"></p> -->

          <div class="btn-area" v-if="timer && keep.status!=5 && !(keep.isPay - 0)">

            <img src="../../../assets/icon-m.png" @click="show = true" alt="" />

            <div class="desc">立即支付</div>

          </div>



        </div>
        <div class="order">
          <div class="title">订单信息</div>
          <ul>

            <li>
              <div class="name ">订单编号</div>
              <div class="info copy" :data-clipboard-text="keep.orderNo" @click="copy()">{{ keep.orderNo }}</div>
            </li>
            <li>
              <div class="name">服务名称</div>
              <div class="info">{{ keep.name }}</div>
            </li>
            <li>
              <div class="name">服务时间</div>
              <div class="info">{{ keep.visitTime }}</div>
            </li>
            <li>
              <div class="name">服务地点</div>
              <div class="info">{{ keep.visitAddress }}</div>
            </li>
          </ul>
        </div>
        <div class="order">
          <div class="title">收费明细</div>
          <ul>
            <li>
              <div class="name">服务价格</div>
              <div class="info">￥{{ keep.listOrderItem[0].salesPrice }}</div>
            </li>
            <li>
              <div class="name">优惠</div>
              <div class="info">￥{{ keep.listOrderItem[0].salesPrice - keep.listOrderItem[0].paymentPrice }}</div>

            </li>
          </ul>
          <div class="bottom">
            合计：
            <div class="color">
              ￥<span>{{ keep.listOrderItem[0].paymentPrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-action-sheet v-model="show" title="支付">
      <div class="content">
        <van-radio-group v-model="payType">
          <van-cell-group>
            <van-cell clickable @click="payType = '1'">
              <template #title>
                <img class="img" src="../../../assets/img/weChat-icon.png" alt />
                <span class="custom-title">微信</span>
              </template>
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
            <span class="line"></span>
            <van-cell clickable @click="payType = '2'">
              <template #title>
                <img class="img" src="../../../assets/img/alipay-icon.png" alt />
                <span class="custom-title">支付宝</span>
              </template>
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div class="pay" @click="pay()">支付</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
  import Head from "../../../components/head/index.vue";
  import { getOrderDetail } from "@/api/orderDetail";
  import { mallPay } from "../../../common/mallPay"; //自营商城支付
  import { cancel } from "../../../api/cancelOrder/index";
  import { Dialog } from "vant"
  // , cancelOrder, receiveOrder
  export default {
    data() {
      return {
        keep: null,
        payType: "1",
        show: false,
        timer: null,
        timerId: 0,
      };
    },

    created() {
      window.closePage = this.closePage

      this.getInfo()

    },
    methods: {

      //请求支付
      pay() {
        mallPay(this.keep.id, this.payType).then((res) => {
          if (res.data.data == 0) {
            this.getInfo()
          }
        });
      },
      // 订单信息
      getInfo() {
        getOrderDetail(this.$route.query.id).then((res) => {
          try {
            window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({ color: '#f6f6f6' }))
          } catch {
            //
          }
          if (res.data.code === 0) {
            this.keep = res.data.data;
            this.getOneTime();
          }
        });

      },
      //支付回调
      closePage(data) {

        !data && this.getInfo()
      },
      //判断订单是否超过支付时间
      getOneTime() {
        if (this.keep.status == "5" || this.keep.isPay != "0") {
          return;
        }


        if ((Date.now() - (new Date(this.keep.createTime.replace(/-/ig, '/')).getTime() + 15 * 60 * 1000)) < 0) {
          this.timer = true
        } else {
          this.timer = false
          return

        }


        this.timerId = setInterval(() => {
          if ((Date.now() - (new Date(this.keep.createTime.replace(/-/ig, '/')).getTime() + 15 * 60 * 1000)) < 0) {
            this.timer = true
          } else {
            this.timer = false
            clearInterval(this.timerId)

          }
        }, 1000)


      },

      // 取消订单
      remove() {
        cancel(this.keep.id).then((res) => {
          if (res.data.code === 0) {
            this.keep.status = "5";
            clearInterval(this.timerId)
            this.$taost.success("取消成功");
          } else {
            this.$taost.fail("取消失败");
          }
        });
      },

      // 删除订单
      delOrder() {


        Dialog.confirm({
          title: '提示',
          message: '是否确认删除订单',
        })
          .then(() => {

            this.deleteOrder({ type: '1', id: this.keep.id }).then(() => {
              this.$router.back()
            })

          })




      },
      detail() {
        try {
          window.webkit.messageHandlers.jump.postMessage(JSON.stringify({ type: 2, id: this.keep.listOrderItem[0].spuId }))

        } catch {

          window.android.goRecruit(2, this.keep.listOrderItem[0].spuId)
        }
      },
      copy() {


        let clipboard = new this.Clipboard('.copy');
        clipboard.on("success", () => {
          this.$toast("复制成功");
          // 释放内存
          clipboard.destroy();
        });
        clipboard.on("error", () => {
          // 不支持复制
          this.$toast("该浏览器不支持自动复制");
          // 释放内存
          clipboard.destroy();
        });


      },
    },
    mounted() {
      this.setRem();
    },
    components: {
      Head,
    },
    beforeDestroy() {
      clearInterval(this.timerId)
      try {
        window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({}))
      } catch {
        //
      }
    }
  };
</script>

<style lang="less" scoped>
  .keep {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    font-family: PingFangSC-Medium, PingFang SC;
    background: #f6f6f6;

    .main {
      flex: 1;
      overflow: auto;
      padding: 0 0.133333rem;

      .warp {
        padding-top: 0.533333rem;

        .title {
          font-size: 0.56rem;
          color: #333;
          margin-bottom: 0.266667rem;
          padding-left: 0.4rem
        }

        .btns {
          border-radius: 0.266667rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.48rem .92rem;
          background-color: white;
          margin-bottom: 0.293333rem;
          box-sizing: border-box;

          .btn-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;

            img {
              width: 0.666667rem;
              height: 0.64rem;
              margin-bottom: 0.133333rem;
            }

            div {
              font-size: 0.32rem;
              transform: scale(0.83);
            }
          }

          p {
            width: 0.026667rem;
            height: 0.96rem;
            background: #e5e5e5;
          }
        }

        .order {
          border-radius: 0.266667rem;
          background-color: white;
          padding: 0.266667rem 0.293333rem;
          margin-bottom: 0.266667rem;

          .title {
            font-weight: 600;
            font-size: 0.426667rem;
            color: #333;
            padding-bottom: 0.293333rem;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 0.106667rem;
          }

          li {
            display: flex;
            justify-content: space-between;
            padding: 0.133333rem 0;
            font-size: 0.373333rem;
            color: #999;
            font-family: PingFangSC-Regular, PingFang SC;

            .info {
              width: 5.6rem;
              text-align: right;
            }
          }

          .bottom {
            font-size: 0.373333rem;
            color: #333;
            font-family: PingFangSC-Regular, PingFang SC;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .color {
              color: #a80000;

              span {
                font-size: 0.48rem;
              }
            }
          }
        }
      }
    }
  }

  .content {
    padding: 0 0.133333rem;
    flex: 1;
    overflow: hidden;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    img {
      width: .666667rem;
      height: .666667rem;
      margin-right: .266667rem;
    }

    .van-cell__title {
      display: flex;
      align-items: center;
    }

    ul {
      padding-top: 0.4rem;
      height: 100%;
      width: 100%;
      overflow: auto;
      display: block;
      padding-bottom: .666667rem;

      .item1 {
        padding: 0.586667rem 0 0.373333rem;

        .title {
          display: flex;
          justify-content: space-between;
          font-size: 0.373333rem;
          font-weight: 500;
          margin-bottom: 0.48rem;

          .right {
            color: #ff5200;
          }
        }

        .good-info {
          display: flex;
          height: 2.133333rem;

          img {
            width: 2.133333rem;
            height: 2.133333rem;
            margin-right: 0.266667rem;
          }

          .good-box {
            font-size: 0.32rem;
            color: #333;

            .name {
              width: 4.853333rem;
              height: 1.36rem;
              margin-bottom: 0.26666rem;
            }

            .order-info {
              color: #999;
              font-size: 0.32rem;
            }
          }
        }

      }
    }

    .pay {
      width: 8.24rem;
      height: 0.96rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 0.4rem;
      font-weight: 500;
      background-color: #e64d42;
      margin: 1.533333rem auto 0.866667rem;
      border-radius: 0.56rem;



    }

  }
</style>